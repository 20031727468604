<template>
  <validation-observer
    ref="setOfCriteriaCopyFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="setOfCriteriaCopyModal"
      body-class="position-static"
      centered
      title="Sao chép phiếu khảo sát/đánh giá"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
      size="lg"
    >
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group label-for="setOfEvaluateLevelId">
            <template v-slot:label>
              Loại phiếu khảo sát/đánh giá<span class="text-danger">*</span>
            </template>
            <v-select
              placeholder="Chọn loại phiếu khảo sát/đánh giá"
              v-model="targetSetOfCriteria.setOfCriteriaTypeId"
              label="name"
              :options="setOfCriteriaTypes"
              :reduce="option => option.id"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label-for="name">
            <template v-slot:label>
              Tên phiếu khảo sát/đánh giá <span class="text-danger">*</span>
            </template>
            <validation-provider
                #default="{ errors }"
                name="Tên phiếu"
                rules="required"
              >
                <b-form-textarea
                  id="name"
                  placeholder="Nhập tên phiếu"
                  rows="3"
                  v-model="targetSetOfCriteria.name"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label-for="code">
            <template v-slot:label>
              Mã phiếu <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Mã phiếu"
              rules="required"
            >
              <b-form-input
                id="code"
                v-model="targetSetOfCriteria.code"
                name="code"
                placeholder="Nhập mã phiếu"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label-for="setOfEvaluateLevelId">
            <template v-slot:label>
              Thang điểm đánh giá tổng quan
            </template>
            <v-select
              placeholder="Chọn thang điểm đánh giá tổng quan"
              v-model="targetSetOfCriteria.setOfEvaluateLevelId"
              label="name"
              :options="setOfEvaluateLevels"
              :reduce="option => option.id"
              @input="setOfEvaluateLevelOnChange"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label-for="status">
            <template v-slot:label>
              Trạng thái <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Trạng thái"
              rules="required"
            >
              <v-select
                v-model="targetSetOfCriteria.status"
                :options="statusOptions"
                :reduce="option => option.value"
              />
              <b-form-invalid-feedback :state="getElementState(errors)">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Sao chép
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('setOfCriteriaCopyModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  BForm,
  BOverlay,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BFormTextarea
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'

export default {
  name: 'SetOfCriteriaCopy',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetSetOfCriteria: {
        id: null,
        organizationId: 0,
        name: '',
        code: '',
        status: 1,
        orderNo: 1,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: 'setOfCriteria/statuses',
      resourceName: 'programme/resourceName',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      setOfCriteriaTypes: 'setOfCriteria/setOfCriteriaTypes',
      setOfEvaluateLevels: 'setOfCriteria/setOfEvaluateLevels',
    }),
    statusOptions() {
      return this.statuses.map(item => ({ value: item.value, label: item.label }))
    },
    isCreated() {
      return !this.mainData
    },
    getOrganizationId() {
      const cUser = getUser()
      return cUser.orgId ? cUser.orgId : 0
    },
  },
  methods: {
    ...mapActions({
      copySetOfCriteria: 'setOfCriteria/copySetOfCriteria',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
    }),
    async onTrainingSystemsChange(event) {
      this.targetData.courseId = null
      await this.getCourses({ trainingSystemId: event })
    },
    async onDepartmentChange(event) {
      this.targetData.majorId = null
      await this.getMajors({ departmentId: event })
    },
    async onMajorChange(event) {
      this.targetData.name = `${this.majors.find(item => item.value === this.targetData.majorId).label} ${
        this.courses.find(item => item.value === this.targetData.courseId).label.substring(0, 3)}`
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      if (this.mainData) {
        this.targetSetOfCriteria = { ...this.mainData }
        this.targetSetOfCriteria.name = `${this.targetSetOfCriteria.name} Copy`
        this.targetSetOfCriteria.code = `${this.targetSetOfCriteria.code} Copy`
      }
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .setOfCriteriaCopyFormRef
        .reset()
        this.targetSetOfCriteria = {
        id: null,
        organizationId: 0,
        name: '',
        code: '',
        status: 1,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .setOfCriteriaCopyFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.copySetOfCriteria(this.targetSetOfCriteria)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('setOfCriteriaCopyModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
